"use client";

import Lottie from "lottie-react";
import { useTranslations } from "next-intl";
import { memo } from "react";

import DigitalServicesAnimation from "@/resources/animation/digital-services-animation.json";

const HeroSection = () => {
  const t = useTranslations();
  // const locale = useLocale();
  // const router = useRouter();

  /**
   * handle go to Services
   */
  // const handleGoToServices = useCallback(() => {
  //   router.push(SERVICES_PATH);
  // }, []);

  /**
   * handle go to About
   */
  // const handleGoToAbout = useCallback(() => {
  //   router.push(ABOUT_PATH);
  // }, []);

  return (
    <div className="relative isolate overflow-hidden">
      <div className="mx-auto max-w-7xl px-6 pb-20 pt-24 sm:pb-24 sm:pt-32 md:pb-32 lg:px-8 lg:pt-40">
        <div className="mx-auto grid grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6">
          <div className="items-center text-justify lg:mt-0 lg:max-w-xl lg:text-left">
            <h1 className="text-center text-3xl font-bold !leading-[66px] tracking-tight text-gray-900 sm:text-4xl md:text-left xl:text-5xl">
              {t("home.header_title1")}
              <br />
              <span className="inline-block -skew-x-12 bg-blue-700 px-4 !leading-[66px] text-white text-opacity-100">
                {t("home.header_title2")}
              </span>
            </h1>

            <p className="mt-8 text-lg leading-8 text-gray-600">{t("home.header_description1")}</p>
            <p className="text-lg leading-8 text-gray-600">{t("home.header_description2")}</p>

            {/* <div className="grid grid-cols-1 items-center justify-start gap-6 gap-x-6 md:flex">
              <Button
                size="large"
                color="primary"
                variant="contained"
                className="justify-center !px-6"
                onClick={handleGoToServices}
              >
                {t("home.view_services")}
              </Button>
              <Button
                onClick={handleGoToAbout}
                size="large"
                variant="text"
                color="primary"
                className="justify-center !px-6"
              >
                {t("home.learn_more_about_helpful")} <span aria-hidden="true">→</span>
              </Button>
            </div> */}
          </div>
          <div className="mt-24 w-full rounded-2xl object-cover sm:mt-32 lg:mt-0">
            <Lottie loop={true} autoplay={true} animationData={DigitalServicesAnimation} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(HeroSection);
