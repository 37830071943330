"use client";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { button } from "framer-motion/client";
import { useLocale, useTranslations } from "next-intl";
import { createElement, useTransition } from "react";
import { memo, useCallback, useMemo, useRef } from "react";

import { Icons } from "@/components/atoms";
import { LOCALES_SWITCHER } from "@/constants/locale";
import { usePathname, useRouter } from "@/i18n/routing";
/**
 * LocaleSwitcher
 * @returns JSX element
 */
const LocaleSwitcher = () => {
  const t = useTranslations();
  const [isPending, startTransition] = useTransition();
  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();

  const buttonRef = useRef(null);

  const curentLocales = useMemo(() => {
    return LOCALES_SWITCHER.find((item) => item.locale === locale) || LOCALES_SWITCHER[0];
  }, [locale]);

  /**
   * handle locale change
   * @param locale string
   */
  const handleLocaleChange = useCallback(
    (locale: string) => () => {
      startTransition(() => {
        router.replace(pathname, { locale });
      });

      if (buttonRef.current) {
        (buttonRef.current as HTMLButtonElement)?.click();
      }
    },
    [pathname, router]
  );

  return useMemo(() => {
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div className="flex items-center justify-center">
          <MenuButton className="w-full" disabled={isPending}>
            {createElement(Icons[curentLocales.icon], {
              className: "size-6",
              "aria-label": t(curentLocales.name),
            })}
          </MenuButton>
        </div>

        <MenuItems
          transition
          className="absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            {LOCALES_SWITCHER.map(({ locale, name, icon }, index) => (
              <MenuItem key={index} as={button} onClick={handleLocaleChange(locale)} className="cursor-pointer">
                <div className="group flex items-center text-nowrap px-4 py-2 text-sm text-gray-900 hover:text-blue-600">
                  {createElement(Icons[icon], {
                    className: "size-6 group-hover:cursor-pointer mr-3 text-gray-400",
                    "aria-label": t(name),
                  })}
                  {t(name)}
                </div>
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Menu>
    );
  }, [curentLocales.icon, curentLocales.name, handleLocaleChange, isPending, t]);
};

export default memo(LocaleSwitcher);
