// Contact social media
export const CONTACT_TIKTOK = process.env.NEXT_PUBLIC_CONTACT_TIKTOK || "#";
export const CONTACT_YOUTUBE = process.env.NEXT_PUBLIC_CONTACT_YOUTUBE || "#";
export const CONTACT_FACEBOOK = process.env.NEXT_PUBLIC_CONTACT_FACEBOOK || "#";

// Google Analytics
export const GA_ID = process.env.NEXT_PUBLIC_GA_ID || "";

// Contact information
export const WEBSITE_URL = process.env.NEXT_PUBLIC_WEBSITE_URL || "";
export const CONTACT_MY_EMAIL = process.env.NEXT_PUBLIC_CONTACT_MY_EMAIL || "";
export const CONTACT_EMAIL = process.env.NEXT_PUBLIC_CONTACT_EMAIL || "";
export const CONTACT_PHONE = process.env.NEXT_PUBLIC_CONTACT_PHONE || "";
