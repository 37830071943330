import { useTranslations } from "next-intl";
import { memo } from "react";
import { HiEnvelope, HiPhone } from "react-icons/hi2";

import { Icons } from "@/components/atoms";
import { CONTACT_EMAIL, CONTACT_PHONE } from "@/configs/environment";
import { LEFT_MENU_ITEMS, RIGHT_MENU_ITEMS } from "@/configs/menu";
import { Link } from "@/i18n/routing";
import { twClass } from "@/utils/common";

const navigation = [
  {
    name: "Email",
    href: `mailto: ${CONTACT_EMAIL}`,
    icon: <HiEnvelope aria-hidden="true" className="size-6" />,
  },
  {
    name: "Phone",
    href: `tel: ${CONTACT_PHONE}`,
    icon: <HiPhone aria-hidden="true" className="size-5" />,
  },
];

const Footer = () => {
  const t = useTranslations();

  return (
    <footer className="bg-blue-700" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8">
        <div className="xl:grid xl:grid-cols-4 xl:gap-8">
          <div className="space-y-6">
            <Link href="/">
              <span className="sr-only">{t("common.app_name")}</span>
              <Icons.Logo color="#ffffff" />
            </Link>
            <div className="flex space-x-6">
              {navigation.map(({ name, icon: Icon, href }) => (
                <Link key={name} href={href} className="font-semibold text-white hover:text-gray-400" target="_blank">
                  <span className="sr-only">{name}</span>
                  {Icon}
                </Link>
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-3 xl:mt-0">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              {(LEFT_MENU_ITEMS.subItems || []).map((item, index) =>
                index % 2 === 0 ? (
                  <div className="mt-10 md:mt-0" key={`${index}`}>
                    <h3 className="text-sm font-semibold leading-6 text-white">{t(`${item.name}`)}</h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {(item.subItems || []).map((item) => (
                        <li key={item.name}>
                          {item.url ? (
                            <Link
                              href={item.url || "#"}
                              className={twClass("text-sm leading-6 text-white hover:text-gray-400", {
                                "lg:hidden": !item.url,
                              })}
                            >
                              {t(`${item.name}`)}
                            </Link>
                          ) : (
                            <p
                              className={twClass("text-sm leading-6 text-white hover:text-gray-400", {
                                "lg:hidden": !item.url,
                              })}
                            >
                              {t(`${item.name}`)}
                            </p>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div key={`${index}`}>
                    <h3 className="text-sm font-semibold leading-6 text-white">{t(`${item.name}`)}</h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {(item.subItems || []).map((item) => (
                        <li key={item.name}>
                          {item.url ? (
                            <Link
                              href={item.url || "#"}
                              className={twClass("text-sm leading-6 text-white hover:text-gray-400", {
                                "lg:hidden": !item.url,
                              })}
                            >
                              {t(`${item.name}`)}
                            </Link>
                          ) : (
                            <p
                              className={twClass("text-sm leading-6 text-white hover:text-gray-400", {
                                "lg:hidden": !item.url,
                              })}
                            >
                              {t(`${item.name}`)}
                            </p>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </div>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              {(RIGHT_MENU_ITEMS.subItems || []).map((item, index) =>
                index % 2 === 0 ? (
                  <div className="mt-10 md:mt-0" key={`${index}`}>
                    <h3 className="text-sm font-semibold leading-6 text-white">{t(`${item.name}`)}</h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {(item.subItems || []).map((item) => (
                        <li key={item.name}>
                          {item.url ? (
                            <Link
                              href={item.url || "#"}
                              className={twClass("text-sm leading-6 text-white hover:text-gray-400", {
                                "lg:hidden": !item.url,
                              })}
                            >
                              {t(`${item.name}`)}
                            </Link>
                          ) : (
                            <p
                              className={twClass("text-sm leading-6 text-white hover:text-gray-400", {
                                "lg:hidden": !item.url,
                              })}
                            >
                              {t(`${item.name}`)}
                            </p>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div key={`${index}`}>
                    <h3 className="text-sm font-semibold leading-6 text-white">{t(`${item.name}`)}</h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {(item.subItems || []).map((item) => (
                        <li key={item.name}>
                          {item.url ? (
                            <Link
                              href={item.url || "#"}
                              className={twClass("text-sm leading-6 text-white hover:text-gray-400", {
                                "lg:hidden": !item.url,
                              })}
                            >
                              {t(`${item.name}`)}
                            </Link>
                          ) : (
                            <p
                              className={twClass("text-sm leading-6 text-white hover:text-gray-400", {
                                "lg:hidden": !item.url,
                              })}
                            >
                              {t(`${item.name}`)}
                            </p>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-white pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-white">&copy; 2024 helpful.vn, Inc. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default memo(Footer);
