"use client";

import {
  CloseButton,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslations } from "next-intl";
import React, { memo, useCallback, useMemo, useState } from "react";

import { Icons } from "@/components/atoms";
import { LocaleSwitcher } from "@/components/organisms";
import { Link } from "@/i18n/routing";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const t = useTranslations();

  const tools = useMemo(() => {
    return [{ name: t("header.slug_generator"), href: "/tools/slug-generator" }];
  }, [t]);

  const games = useMemo(() => {
    return [
      { name: t("header.memory_game"), href: "/games/memory-game" },
      { name: t("header.random_alphabet"), href: "/games/random-alphabet" },
      { name: t("header.word_search_game"), href: "/games/word-search-game" },
    ];
  }, [t]);

  /**
   * Handles the toggle of the navbar menu.
   * @param isOpen - Indicates whether the navbar menu is open or closed.
   */
  const handleNavbarMenuToggle = useCallback(
    (isOpen: boolean) => () => {
      setIsMenuOpen(isOpen);
    },
    []
  );

  return (
    <header className="sticky inset-0 z-40 border-b border-gray-200 bg-white">
      <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-6 px-4 sm:px-6 lg:px-8">
        <div className="flex lg:flex-1">
          <Link href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">{t("common.app_name")}</span>
            <Icons.Logo />
          </Link>
        </div>
        <div className="flex gap-6 md:gap-10 lg:hidden">
          <LocaleSwitcher />
          <button
            type="button"
            onClick={handleNavbarMenuToggle(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">{t("header.open_main_menu")}</span>
            <Bars3Icon aria-hidden="true" className="size-6" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          <Link href="/" className="text-sm/6 font-semibold text-gray-900">
            {t("header.home")}
          </Link>

          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm/6 font-semibold text-gray-900 !outline-none hover:!outline-none">
              {t("header.games")}
              <ChevronDownIcon aria-hidden="true" className="size-5 flex-none text-gray-400" />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-50 mt-3 whitespace-nowrap rounded-md bg-white p-2 shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              {games.map((item, index) => (
                <CloseButton
                  key={index}
                  as={Link}
                  href={item.href}
                  className="block rounded-md px-3 py-2 text-sm/6 font-semibold text-gray-900 hover:bg-gray-50"
                >
                  {item.name}
                </CloseButton>
              ))}
            </PopoverPanel>
          </Popover>

          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm/6 font-semibold text-gray-900 !outline-none hover:!outline-none">
              {t("header.tools")}
              <ChevronDownIcon aria-hidden="true" className="size-5 flex-none text-gray-400" />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-50 mt-3 whitespace-nowrap rounded-md bg-white p-2 shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              {tools.map((item, index) => (
                <CloseButton
                  key={index}
                  as={Link}
                  href={item.href}
                  className="block rounded-md px-3 py-2 text-sm/6 font-semibold text-gray-900 hover:bg-gray-50"
                >
                  {item.name}
                </CloseButton>
              ))}
            </PopoverPanel>
          </Popover>

          <Link href="/learn-vietnamese" className="text-sm/6 font-semibold text-gray-900">
            {t("header.learn_vietnamese")}
          </Link>
          <Link href="#" className="text-sm/6 font-semibold text-gray-900">
            {t("header.blog")}
          </Link>
        </PopoverGroup>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <LocaleSwitcher />
        </div>
      </nav>
      <Dialog open={isMenuOpen} onClose={setIsMenuOpen} className="lg:hidden">
        <DialogBackdrop className="fixed inset-0 z-50 bg-black/30" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">{t("common.app_name")}</span>
              <Icons.Logo />
            </Link>
            <div className="flex gap-6 md:gap-10 lg:hidden">
              <LocaleSwitcher />
              <button
                type="button"
                onClick={handleNavbarMenuToggle(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">{t("header.close_menu")}</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button>
            </div>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  href="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                  onClick={handleNavbarMenuToggle(false)}
                >
                  {t("header.home")}
                </Link>

                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                    {t("header.games")}
                    <ChevronDownIcon aria-hidden="true" className="size-5 flex-none group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {games.map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as={Link}
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50"
                        onClick={handleNavbarMenuToggle(false)}
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure>

                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                    {t("header.tools")}
                    <ChevronDownIcon aria-hidden="true" className="size-5 flex-none group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {tools.map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as={Link}
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50"
                        onClick={handleNavbarMenuToggle(false)}
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure>

                <Link
                  href="/learn-vietnamese"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                  onClick={handleNavbarMenuToggle(false)}
                >
                  {t("header.learn_vietnamese")}
                </Link>

                <Link
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                  onClick={handleNavbarMenuToggle(false)}
                >
                  {t("header.blog")}
                </Link>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default memo(Header);
