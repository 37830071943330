import { MenuItemType } from "@/types/header";

export const LEFT_MENU_ITEMS: MenuItemType = {
  name: "",
  subItems: [
    {
      name: "header.games",
      subItems: [
        {
          name: "header.memory_game",
          url: "/games/memory-game",
        },
        {
          name: "header.random_alphabet",
          url: "/games/random-alphabet",
        },
        {
          name: "header.word_search_game",
          url: "/games/word-search-game",
        },
      ],
    },
    {
      name: "header.tools",
      subItems: [
        {
          name: "header.slug_generator",
          url: "/tools/slug-generator",
        },
      ],
    },
  ],
};

export const RIGHT_MENU_ITEMS: MenuItemType = {
  name: "",
  subItems: [
    {
      name: "header.more_features",
      subItems: [
        {
          name: "header.blog",
          url: "#",
        },
        {
          name: "header.learn_vietnamese",
          url: "/learn-vietnamese",
        },
        {
          name: "header.empty",
          url: "",
        },
      ],
    },
    {
      name: "common.app_name",
      subItems: [
        {
          name: "header.empty",
          url: "",
        },
      ],
    },
  ],
};
